import RedeemRewards from '@/assets/images/app_stores/redeem_rewards.png'
import React from 'react';

export const RedeemRewardsPage = () => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={RedeemRewards} alt="Redeem Now"  width='350px'/>
    </div>
  );
}
