import * as React from 'react';
import { PurchaseStep } from './PurchaseStep';
import { onboard } from '@/features/auth/api/onboard';
import { usePaymentFlowStore } from '../stores/payment_flow';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup
  .object({
    mobile_number: yup.string().required().min(10).max(10)
  })
  .required()
export const LetsGetStarted = () => {
  const [loading, setLoading] = React.useState(false)
  const { otpToken, current_step, setState } = usePaymentFlowStore()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = async (data: any) => {
    const mobile_number = `91${data.mobile_number}`
    setLoading(true)
    const result = await onboard({
      account: {
        full_phone_number: mobile_number
      }
    });
    if (result.token) {
      setState({
        otpToken: result.token,
        current_step: "otp_verification",
        full_phone_number: mobile_number,
        token: undefined
      })
    }
    setLoading(false)
  }


  return (
    <PurchaseStep title="Let's Get Started" active={current_step == "get_otp"} completed={otpToken != null || current_step == "payment"} id={1} >
      <div className="mb-3 col-12 col-md-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          {errors.mobile_number && <p className='text-danger mb-2' >{errors?.mobile_number?.message?.replace(/_/g, " ")}</p>}
          <input type="tel" {...register("mobile_number", { required: true })} className="form-control p-2 rounded-2" placeholder="Enter Whatsapp Number" />
          <div className="d-grid my-4">
            <button className="btn btn-primary rounded-2 p-2" disabled={loading} type={'submit'} >
              {loading ? 'Loading...' : 'Get Otp'}
            </button>
          </div>
        </form>
      </div>
    </PurchaseStep>
  );
}

