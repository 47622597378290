import React, {lazy, useEffect, useRef, useState,  Suspense} from 'react';
import '../../../styles/deck.scss';
import ExploreDeckCard from '@/components/Elements/ExploreDeckCard';
import DeckFooter from '@/components/Layout/DeckFooter';
import { useDecks } from '@/features/decks/api/getDecks';
import { getUtmMedium } from '@/utils/utility';
import { decksCardsData } from '@/utils/constant';
import { useNavigate } from 'react-router-dom';

const Expert = lazy(() => import('@/features/misc/pages/common/Expert'));
const Convinced = lazy(() => import('@/features/misc/pages/common/Convinced'));
const FAQDeck = lazy(() => import('@/features/misc/pages/common/FAQDeck'));
const Subscribe = lazy(() => import('@/features/misc/pages/common/Subscribe'));
const MainHeader = lazy(() => import('@/features/misc/pages/common/MainHeader'));
const Featured = lazy(() => import('@/features/misc/pages/common/Featured'));
const FeatureSection = lazy(() => import('@/features/misc/pages/common/FeatureSection'));
const HowItWorks = lazy(() => import('@/features/misc/pages/common/HowItWorks'));

const GrowthTitans = () => {
    const navigate = useNavigate();
    const deckRef = useRef<HTMLDivElement | null>(null);
    const sliderRef = useRef<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [decksData, setDecksData] = useState<any[]>([]);
    const [lowestPackage, setLowestPackage] = useState<number | null>(null);
    const utmData = getUtmMedium();
    const decksQuery = useDecks({ config: {} });
    const deviceType = sliderRef.current?.state.deviceType;
    const visibleSlides = deviceType === 'mobile' ? 1 : 3;

    useEffect(() => {
        if (!decksQuery.isLoading && decksQuery.data?.length > 0) {
            filterDataBasedOnTime();
        }
    }, [decksQuery.data]);

    const filterDataBasedOnTime = () => {
        const data = decksQuery.data || [];
        let filteredData: any[] = [];
        let tempLowestPackage = Infinity;

        data.forEach((deck: any) => {
            if (deck.title === 'Growth Titans') {
                deck.channel_packages.forEach((item: any) => {
                    if (!item.sTimebase && item.price < tempLowestPackage) {
                        tempLowestPackage = item.price;
                        filteredData.push(deck);
                    }
                });
            }
        });

        setDecksData(filteredData);
        setLowestPackage(tempLowestPackage === Infinity ? null : tempLowestPackage);
    };

    const handleNextSlide = () => {
        if (currentSlide < decksCardsData.length - visibleSlides) {
            const nextSlide = currentSlide + 1;
            setCurrentSlide(nextSlide);
            sliderRef.current?.goToSlide(nextSlide);
        }
    };

    const handlePreviousSlide = () => {
        if (currentSlide > 0) {
            const prevSlide = currentSlide - 1;
            setCurrentSlide(prevSlide);
            sliderRef.current?.goToSlide(prevSlide);
        }
    };

    const navigateToPayment = () => {
        if (decksData.length > 0) {
            navigate(`/decks/purchase/${decksData[0].id}/${decksData[0].channel_packages[0].id}?${utmData}`);
        }
    };

    return (
        <div className='deck-body'>
            <Suspense fallback={<div>.</div>}>
            <MainHeader deckRef={deckRef} />
            <Featured />
            <FeatureSection />

            <section className='works container'>
                <h2 className='text-center deck-common-sub-heading'>How it works</h2>
                <HowItWorks />
            </section>

            <section className='explore-decks' ref={deckRef}>
                <h2 className='text-center text-black mb-3 mb-lg-4 deck-common-sub-heading'>Explore our Deck</h2>
                <p className='text-black deck-common-body-md text-center text-capitalize'>Start Building Your Portfolio Today!</p>
                <div className='container d-flex flex-column align-items-center justify-content-center'>
                    {decksQuery.isLoading ? (
                        <p className='fw-medium mt-3'>Loading</p>
                    ) : (
                        decksData.map((deck, index) => (
                            <ExploreDeckCard key={index} deck={deck} selectedTime={undefined} utmData={utmData} />
                        ))
                    )}
                </div>
            </section>

            <Expert />
            <Subscribe />
            <Convinced sliderRef={sliderRef} handlePreviousSlide={handlePreviousSlide} handleNextSlide={handleNextSlide} />
            <FAQDeck />
            <DeckFooter />

            </Suspense>
            {decksData?.length > 0 && (
                <div className='d-flex align-content-center justify-content-between d-md-none position-fixed bottom-0 w-100 offer-section overflow-hidden'>
                    <div>
                        <h3 className='title-offer'>Starts at &#8377;{lowestPackage}</h3>
                        <p className='desc-offer'>
                            <span className='text-decoration-line-through' style={{ fontSize: '0.875rem' }}>&#8377;999</span> Special Launch Offer
                        </p>
                    </div>
                    <button onClick={navigateToPayment}>Subscribe Now</button>
                </div>
            )}
        </div>
    );
};

export default GrowthTitans;
