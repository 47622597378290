import { ReactComponent as ThePrint } from '@/assets/images/featured_in/the_print.svg'
import { ReactComponent as VcCircle } from '@/assets/images/featured_in/vccircle.svg'
import { ReactComponent as YoutStory } from '@/assets/images/featured_in/your_story.svg'
import { ReactComponent as BusinessStandard } from '@/assets/images/featured_in/business_standard.svg'
import { ReactComponent as AniNews } from '@/assets/images/featured_in/ani_news.svg'
import { ReactComponent as ThePrintSmall } from '@/assets/images/featured_in/small/the_print.svg'
import { ReactComponent as VcCircleSmall } from '@/assets/images/featured_in/small/vccircle.svg'
import { ReactComponent as YoutStorySmall } from '@/assets/images/featured_in/small/your_story.svg'
import { ReactComponent as BusinessStandardSmall } from '@/assets/images/featured_in/small/business_standard.svg'
import { ReactComponent as AniNewsSmall } from '@/assets/images/featured_in/small/ani_news.svg'
import HandBulb from '@/assets/images/stock_icons/hand_bulb.svg'
import Badge from '@/assets/images/stock_icons/badge.svg'
import MoneyBag from '@/assets/images/stock_icons/money_bag.svg'
import Rocket from '@/assets/images/stock_icons/rocket.svg'
import { B2CLayout } from '@/components/Layout'
import { Accordion } from 'react-bootstrap'
import MobileViewImg from '@/assets/images/common/MobileViewImg.png'
import featuresMobile from '@/assets/images/common/featuresMobile.png'
import DecksImg from '@/assets/images/common/Vector.webp'
import { getAppURL } from '@/utils/checkOS'
import TestimonialCard from '@/components/Elements/TestimonialCard'
import partyPopper from '@/assets/images/common/party-popper.png'
import DeckCard from '@/components/Elements/DeckCard'
import FeatureCard from '@/components/Elements/FeatureCard'
import Carousel from 'react-multi-carousel'
import AppleLogo from '@/assets/images/common/apple-logo.png'
import GooglePlayLogo from '@/assets/images/common/google-play-logo.png'
import { useNavigate } from 'react-router-dom'
import { getUtmMedium } from '@/utils/utility'
import { homeDecksData, homeFeaturesData, homeTestimonialData } from '@/utils/constant'

export const HomePage = () => {

  const navigate = useNavigate();
  let appURL = getAppURL();
  const utmData = getUtmMedium();

  const TestimonialButtonGroup = ({ next, previous }: any) => {
    return (
      <div className="container position-relative d-sm-none d-lg-block">
        <div onClick={() => previous()} className='slider-prev-button cursor-pointer' >
          <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: '#000' }}></i>
        </div>

        <div onClick={() => next()} className='slider-next-button cursor-pointer' >
          <i className="bi bi-chevron-right" style={{ fontSize: '30px', color: '#000' }}></i>
        </div>
      </div>
    );
  };

  const DecksButtonGroup = ({ next, previous }: any) => {
    return (
      <div className="container position-relative">
        <div onClick={() => previous()} className='slider-prev-button-decks' >
          <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: '#000' }}></i>
        </div>
        <div onClick={() => next()} className='slider-next-button-decks' >
          <i className="bi bi-chevron-right" style={{ fontSize: '30px', color: '#000' }}></i>
        </div>
      </div>
    );
  };


  const renderLine = () => {
    return (
      <div className="center my-3 d-md-none">
        <svg width="300" height="4" viewBox="0 0 300 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.666667 1.75C0.666667 2.48638 1.26362 3.08333 2 3.08333C2.73638 3.08333 3.33333 2.48638 3.33333 1.75C3.33333 1.01362 2.73638 0.416667 2 0.416667C1.26362 0.416667 0.666667 1.01362 0.666667 1.75ZM296.667 1.75C296.667 2.48638 297.264 3.08333 298 3.08333C298.736 3.08333 299.333 2.48638 299.333 1.75C299.333 1.01362 298.736 0.416667 298 0.416667C297.264 0.416667 296.667 1.01362 296.667 1.75ZM2 2H298V1.5H2V2Z" fill="#22201E" />
        </svg>
      </div>
    )
  }

  return (
    <B2CLayout>
      {/* Hero Section */}
      <section className='hero-container' >
        <div className="container">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-12 col-md-6 mt-0 mt-md-0">
              <button className="focus-btn-1 mb-3">
                <img src={partyPopper} alt="party popper" />
                <p>Trusted by 25K+ Users</p>
              </button>
              <h1 className='text-dark hero-heading text-wrap' >Making Direct Stock Investing Simple & Reliable</h1>
              <p className='open-sans hero-para text-wrap' >Discover community stock ideas and get recommendations from SEBI Registered Experts</p>
              <div className="flex-container">
                <button className="btn-light-blue" onClick={() => {
                  window.location.replace(appURL)
                }} >Download Now</button>
                <div className="img-box">
                  <a href="https://play.google.com/store/apps/details?id=com.OpiGo1final"><img src={GooglePlayLogo} alt="Google play Store" draggable={false} /></a>
                  <a href="https://apps.apple.com/in/app/opigo-stock-market-strategies/id1619955231"><img src={AppleLogo} style={{padding:'2px'}} alt="App Store" draggable={false} /></a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mobile-img" >
              <div className="inner-img-container">
                <img src={MobileViewImg} alt='Mobile App View' width={"100%"} draggable={false} />
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
      {/* Featured Image */}
      <section className='bg-peach d-none d-md-block feature-img' >
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-4 d-md-flex align-items-md-center">
              <p className="main-heading">As Featured In</p>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <ThePrintSmall />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <VcCircleSmall />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <YoutStorySmall />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <BusinessStandardSmall />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <AniNewsSmall />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-peach py-4 px-2 p-md-5 d-md-none feature-img-sm' >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 align-items-center">
              <p className="gray-text main-heading text-center">As Featured In</p>
            </div>
            <div className="col-12 d-flex justify-content-center flex-wrap p-0">
              <div className="d-flex align-items-center m-2">
                <ThePrint />
              </div>
              <div className="d-flex align-items-center m-2">
                <VcCircle />
              </div>
              <div className="d-flex align-items-center m-2">
                <YoutStory />
              </div>
              <div className="d-flex align-items-center m-2">
                <BusinessStandard />
              </div>
              <div className="d-flex align-items-center m-2">
                <AniNews />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Use Opigo */}
      <section className="useOpigo">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-md-start">
              <h1 className='text-dark title-main'>Here’s How Stock Ninjas Are Using OpiGo</h1>
              {renderLine()}
              <h4 className="para-main">New or experienced, we cater to all needs of traders & investors</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-12 text-center text-md-start main-card">
              <div className="main-imgBox">
                <div className="img-box">
                  <img src={HandBulb} alt="Hand Bulb" />
                </div>
              </div>
              <h4 className="card-title" >Get Stock Ideas</h4>
              <p className="card-para">Follow High-Score Users To discover New Market Themes and Stock Ideas</p>
            </div>
            <div className="col-md-3 col-12 text-center text-md-start main-card ">
              <div className="main-imgBox">
                <div className="img-box">
                  <img src={Badge} alt="Hand Bulb" />
                </div>
              </div>
              <h4 className="card-title">Validate Before Investing</h4>
              <p className="card-para">Before you hit the buy button: Check Stock Sentiment & Targets on OpiGo</p>
            </div>
            <div className="col-md-3 col-12 text-center text-md-start main-card">
              <div className="main-imgBox">
                <div className="img-box">
                  <img src={MoneyBag} alt="Hand Bulb" />
                </div>
              </div>
              <h4 className="card-title" >Practice & Earn</h4>
              <p className="card-para">Learn With Zero Risk: Practice Real Investing & Win Daily Cash Rewards</p>
            </div>
            <div className="col-md-3 col-12 text-center text-md-start main-card">
              <div className="main-imgBox">
                <div className="img-box">
                  <img src={Rocket} alt="Hand Bulb" />
                </div>
              </div>
              <h4 className="card-title" >Boost Your Credibility</h4>
              <p className="card-para">Share Stock Ideas To Engage Your Audience & Build Performance Record</p>
            </div>
          </div>
        </div>
      </section>
      {/* Features */}
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-md-start">
              <h1 className='text-dark title-main'>Features Built For Discovering Stocks & Experts Smoothly</h1>
              {renderLine()}
              <h4 className="para-main">Track, share, play, and do a bunch of things for free</h4>
            </div>
          </div>
          <div className="row content-row">
            <div className="col-12 col-md-4">
              <div className="inner-col">
                <div className="img-box">
                  <img src={featuresMobile} alt="Features" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12 col-md-6">
                  {homeFeaturesData.slice(0, 3).map((feature, index) => (
                    <FeatureCard key={index} {...feature} />
                  ))}
                </div>
                <div className="col-12 col-md-6">
                  {homeFeaturesData.slice(3, 6).map((feature, index) => (
                    <FeatureCard key={index} {...feature} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Subscription */}
      <section className="subscription">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="image-container">
                <button className="focus-btn-2 mb-3">
                  <img src={DecksImg} alt="decks" />
                  <p className='fw-normal ms-1'>Decks</p>
                </button>
              </div>
              <div className="col-12 text-center text-md-start">
                <h1 className='title-main'>A Subscription That Tells You Which Stocks To Buy & Sell</h1>
                {renderLine()}
                <p className="para-main">Get Research Backed Calls by 10+ SEBI Reg. Experts</p>
              </div>
              <div className="flex-container">
                <div className="subscription-value">
                  <h1 className='title'>16.2%</h1>
                  <p className="desc">Avg.returns</p>
                </div>
                <div className="subscription-value">
                  <h1 className='title'>₹299/-</h1>
                  <p className="desc">Starts at</p>
                </div>
              </div>
              <button className="btn-light-blue inner-btn" onClick={() => {
                navigate(`decks?${utmData ?? ''}`)
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }} >Explore Decks</button>
            </div>
            <div className="col-12 col-md-6 img-container">
              <div className="img-box">
                <div className='w-100'>
                  <Carousel
                    renderButtonGroupOutside={true}
                    customButtonGroup={<DecksButtonGroup />}
                    additionalTransfrom={0}
                    arrows={false}
                    draggable={false}
                    centerMode={false}
                    focusOnSelect={false}
                    infinite={true}
                    renderDotsOutside={false}
                    responsive={{
                      main: {
                        breakpoint: {
                          max: 3000,
                          min: 0
                        },
                        items: 1,
                      },
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                  >
                    {
                      homeDecksData?.map((deck, index) => (
                        <DeckCard {...deck} key={index} />
                      ))
                    }
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button className="btn-light-blue outer-btn" onClick={() => {
              navigate('decks')
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }} >Explore Decks</button>
          </div>
        </div>
      </section>

      {/* testimonial */}
      <section className="testimonial user-testimonial">
        <div className="container">
          <div className="col-12 text-center text-md-start">
            <h1 className='title-main'>See What Our Users Say</h1>
            {renderLine()}
            <p className="para-main d-none d-md-block">Trust them if not us :)</p>
            <p className="para-main d-md-none">Explore success stories our users spanning over 150 cities</p>
          </div>
          <Carousel
            renderButtonGroupOutside={true}
            customButtonGroup={<TestimonialButtonGroup />}
            additionalTransfrom={0}
            arrows={false}
            draggable={true}
            infinite={true}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1400
                },
                items: 3,
                partialVisibilityGutter: 90
              },
              tablet: {
                breakpoint: {
                  max: 1400,
                  min: 1200
                },
                items: 3,
                partialVisibilityGutter: 10
              },

              smallTabs: {
                breakpoint: {
                  min: 770,
                  max: 1200
                },
                items: 2,
              },
              mobile: {
                breakpoint: {
                  max: 770,
                  min: 0
                },
                items: 1,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass="slider-container"
            slidesToSlide={1}
            partialVisible={true}
          >
            {
              homeTestimonialData.map((item, index) => {
                return <TestimonialCard {...item} key={index} index={index} />
              })
            }
          </Carousel>
        </div>
      </section>


      {/* FAQ's */}
      <section className='faq' >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="title-main text-center text-md-start px-1">Frequently asked questions</h1>
            </div>
            <div className="col-12 p-0">
              <Accordion>
                <Accordion.Item eventKey="faq_0" className='faq-box'>
                  <Accordion.Header className='py-md-2 fs-20' ><span className='fw-medium faq-box' >What does OpiGo do?</span></Accordion.Header>
                  <Accordion.Body>
                      OpiGo is an application that makes your stock investing journey seamless using the following offerings:
                      <br /><br />
                      1. Community: A free platform to discover and share new stock ideas. You can use it to research about stocks, know the market sentiments, get opinions on your portfolio from other members and discuss anything related to investing.
                      <br /><br />
                      2. Decks: A subscription that helps you get stock recommendations from SEBI-registered experts directly.
                      <br /><br />
                      3. PlayZone: Participate in daily contest, enhance your stock picking skills and win cash rewards daily.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="faq_1">
                  <Accordion.Header className='py-md-2 py-1 fs-20' ><span className='fw-medium' >What are Decks?</span></Accordion.Header>
                  <Accordion.Body>
                    Decks on OpiGo are curated lists of stock recommendations provided by SEBI-registered experts. Each deck focuses on specific categories or themes, such as short-term trades, long-term investments, or specific sectors. Users can choose decks based on their investment goals and preferences, and gain access to timely stock picks from experienced experts.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="faq_2">
                  <Accordion.Header className='py-md-2 py-1 fs-20' ><span className='fw-medium' >New to investing, how do I get started?</span></Accordion.Header>
                  <Accordion.Body>
                  If you're new to investing, choose a Deck and subscribe as per your requirement. Once subscribed, you'll receive stock recommendations from verified experts, advising you on when to buy and sell, with real-time alerts on your Whatsapp and app.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="faq_4" className='remove-border'>
                  <Accordion.Header className='py-md-2 py-1 fs-20' ><span className='fw-medium' >Subscribed to Decks but didn’t get a card?</span></Accordion.Header>
                  <Accordion.Body>
                  Stock recommendations (Cards) are sent to you when an expert comes across a Buying/Selling opportunity post-purchase. It might take up to 7 days to receive the 1st card since we believe in quality over quantity. Once a card is published, you will receive it on WhatsApp as well as in the notification section.

                  <br /><br />

                 {" You can check the cards in real-time by going to the App > Decks > My Subscription > Past Cards."}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </B2CLayout>
  );
}
